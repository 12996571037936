(function() {
    'use strict';

    angular.module('common')
        .factory('SessionService', SessionService);

    SessionService.$inject = ['$window'];

    function SessionService($window) {

        var SESSION_INFO = 'sessionInfo';

        var service = {
            setSessionInfo: setSessionInfo,
            getSessionInfo: getSessionInfo,
            removeSessionInfo: removeSessionInfo,
            updateSessionOrganization: updateSessionOrganization,
            updateSessionRole: updateSessionRole,
            updateSessionExpires: updateSessionExpires,
            isExpired: isExpired,
            setAppAccess: setAppAccess,
            getAppAccess: getAppAccess
        };

        function setSessionInfo(sessionInfo) {
            $window.localStorage.setItem(SESSION_INFO, JSON.stringify(sessionInfo));
        }

        function getSessionInfo() {
            return JSON.parse($window.localStorage.getItem(SESSION_INFO));
        }

        function removeSessionInfo() {
            $window.localStorage.removeItem(SESSION_INFO);
        }

        function updateSessionOrganization(org) {
            var sessionInfo = getSessionInfo();
            sessionInfo.organization = org;
            setSessionInfo(sessionInfo);
        }

        function updateSessionRole(role) {
            var sessionInfo = getSessionInfo();
            sessionInfo.role = role;
            setSessionInfo(sessionInfo);
        }

        function updateSessionExpires(tokenExpires) {
            var sessionInfo = getSessionInfo();
            sessionInfo.tokenExpires = tokenExpires;
            setSessionInfo(sessionInfo);
        }

        /**
         * Is the User's authentication expired.
         *
         * No token or no expiration timestamp also means expired.
         *
         * @return {boolean}
         */
        function isExpired() {
            var sessionInfo = getSessionInfo();

            if (!sessionInfo || !sessionInfo.token || !sessionInfo.tokenExpires) {
                return true;
            }

            return Date.now() >= sessionInfo.tokenExpires;
        }

        function setAppAccess(appAccess) {
            var sessionInfo = getSessionInfo();
            sessionInfo.appAccess = appAccess;
            setSessionInfo(sessionInfo);
        }

        /**
         *
         * @param sessionInfoCached - if defined - returns appAccess part of data, if not - in first gets sessionInfo
         * from storage
         * @returns appInfo
         */
        function getAppAccess(sessionInfoCached) {
            if (sessionInfoCached) {
                return sessionInfoCached.appAccess;
            }

            var sessionInfo = getSessionInfo();

            if (sessionInfo) {
                return sessionInfo.appAccess;
            }
        }

        return service;
    }

})();
